import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import fetchWithCSRF from '@core/scripts/helper/fetch-with-csrf';
import meta from '@core/scripts/helper/meta';
import Messages from '@core/scripts/components/messages';
import loadEmbeddedScripts from '@core/scripts/helper/embedded-scripts/embedded-scripts-helper';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope, config} = JshModule('footer/footer');

const path = config.path;

const footerObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.intersectionRatio <= 0) {
            return;
        }

        footerObserver.unobserve(moduleScope);

        loadFooter().then((html) => {
            moduleScope.insertAdjacentHTML('beforeend', html);
            loadEmbeddedScripts(html);
            MessageBroker.publish(Messages.DOM.CHANGED, moduleScope);
        });
    });
}, {
    rootMargin: `0px 0px 800px 0px`
});

footerObserver.observe(moduleScope);


function loadFooter() {
    return new Promise((resolve, reject) => {
        fetchWithCSRF(meta.value("bonprix:baseUrl") + "ajax/footer/" + footerPath() + location.search, {headers: {"Content-Type": "text/html"}})
            .then((res) => {
                return res.text();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function footerPath() {
    if (!path) {
        return "";
    }
    return path + "/";
}
